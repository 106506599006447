export default function modals() {
  setTimeout(function() {
    $('.form-popup').magnificPopup({
      type: 'inline',
      items: {
		      src: '#callback-form'
		    },
      preloader: false
    });

    $(document).on('click', '.close-modal', function(e) {
      e.preventDefault();
      $.magnificPopup.close();
    });	

    $('input[name="phone"]').mask('+7 (999) 999-99-99');

  }, 100);
}
