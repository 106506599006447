import $ from 'jquery';
import 'easy-autocomplete';
import 'magnific-popup';
import 'slick-carousel';
import 'select2';
import validate from './lib/jquery.validate.min';
import mask from './lib/jquery.maskedinput';
import modals from './modals';

$(document).ready(() => {
  var jsonUrl = $('#search-head').data('url');
  $('#search-form').on('submit', function(e) {
    e.preventDefault();
    window.location = '/search?q=' + $( '#search-head' ).val();
  });
  $('#search-head').easyAutocomplete({
    url: jsonUrl,
    getValue: 'name',
    cssClasses: 'search-head',
    template: {
      type: 'custom',
      method: function(value, item) {
        return "<a href='" + item.uri + "' ><div class='search-img'><img src='" + item.icon + "' /></div><div class='search-name'>" + value + "</div><div class='search-cost'>" + item.cost + '</div></a>';
      }
    },
    list: {
      match: {
        enabled: true
      }
    }
  });

  var jsonUrlMob = $('#search-head-mobile').data('url');
  $('#search-form-mobile').on('submit', function(e) {
    e.preventDefault();
    window.location = '/search?q=' + $( '#search-head' ).val();
  });
  $('#search-head-mobile').easyAutocomplete({
    url: jsonUrl,
    getValue: 'name',
    cssClasses: 'search-head-mobile',
    template: {
      type: 'custom',
      method: function(value, item) {
        return "<a href='" + item.uri + "' ><div class='search-img'><img src='" + item.icon + "' /></div><div class='search-name'>" + value + "</div><div class='search-cost'>" + item.cost + '</div></a>';
      }
    },
    list: {
      match: {
        enabled: true
      }
    }
  });

  $('.product-card-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '27px',
    fade: true,
    asNavFor: '.product-card-slider-nav'
  });

  $('.product-card-slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    arrows: true,
    focusOnSelect: true,
    asNavFor: '.product-card-slider'
  });

  $('.select select').select2({
    minimumResultsForSearch: Infinity,
    width: '100%'
  });
  $('.header-mobile-toggle').on('click', function() {
    $(this).toggleClass('active');
  });
  $('.category-filter-item--filter, .filter-close').on('click', function() {
    $('.category-filter-item--filter, .filter-close').toggleClass('active');
    $('.filter-modal').toggleClass('active');
  });


  modals();
});
